.grid {
    text-align: center;
}
.number {
    font-weight: bold;
    padding: 10px;
    line-height: 30px;
    font-size: 2em;
}

.month {
    font-size: 14px;
    color: #999999;
}

.selected {
    background-color: #eeeeee!important;
}
.alert {
    color: #ff0000;
    margin: 10px 0;
}

.input {
    height: 40px;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;

    padding: 18.5px 4px;
    text-align: center;

    padding-top: 10.5px;
    padding-bottom: 8.5px;

    margin-top: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    overflow: hidden;
}

.dot {
    height: 40px;
    position: relative;
    width: 10px;
    padding-top: 10.5px;
    margin-top: 16px;
}
.dot:after {
    content: ".";
    font-weight: 600;
    display: block;
    text-align: center;
    position: absolute;
    bottom: -15px;
    right: -5px;
    width: 10px;
}

.delimiter {
    height: 40px;
    position: relative;
    width: 10px;
    padding-top: 6.5px;
    margin-top: 16px;
    font-weight: 600;
    text-align: center;
}
.delimiter:after {
    content: "-";
    font-weight: 600;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: -5px;
    width: 10px;
}

.keyboard {
    font-weight: bold;
    padding: 10px;
    line-height: 50px;
    font-size: 2em;
}

.icon {
    font-size: 0.875rem!important;
}

.send {
    margin-top: 8px!important;
    line-height: 28px!important;
}

.group {
    flex-direction: row!important;
    font-size: 1.5em;
    justify-content: space-between;
    margin-top: 10px;
}

.group span {
    font-size: 1.5rem;
    font-weight: bold;
}